import { setLoading } from 'appRedux/reducers/Loading';
import { PayScheduleTypeService, EmployeeService, RecurringService } from 'services';
import { PAGINATION_TYPES } from 'util/constants';
import { pagePaginationSuccess, addPaginationItem, updatePaginationItem, deletePaginationItem } from 'appRedux/reducers/Pagination';

export const fetchPayScheduleType = () => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.pay_schedule_types;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await PayScheduleTypeService.find();
      res && dispatch(pagePaginationSuccess({
        data: { count: res.length, total: res.length, items: res.reverse() },
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdatePayScheduleType = payload => {
  return async dispatch => {
    const { id, ...rest } = payload;
    const key = PAGINATION_TYPES.pay_schedule_types;
    let res;
    if (!id) {
      res = await PayScheduleTypeService.create(rest);
      res && dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await PayScheduleTypeService.patch(id, rest);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};

export const deletePayScheduleType = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.pay_schedule_types;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await PayScheduleTypeService.remove({ type_id: id });
      if (res?.success)
        dispatch(deletePaginationItem({
          key,
          value: id,
        }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};

export const assignEmployeeSalaryBasicOrTax = payload => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.employees;
    const loadingObj = {
      key,
      value: true
    };
    let res;
    const { id, route, body } = payload;
    const employees = getState().pagination[key];
    // dispatch(setLoading(loadingObj));
    if (id) {
      res = await EmployeeService.patchItem(id, route, body);
      if (res) {
        const items = [...employees.items.concat(res).reduce((m, o) =>
          m.set(o.id, Object.assign(m.get(o.id) || {}, o))
        , new Map()).values()];
        dispatch(pagePaginationSuccess({
          data: { ...employees, items },
          key
        }));
      }
    }
    loadingObj.value = false;
    // dispatch(setLoading(loadingObj));
    return res;
  };
};

export const handlePayRollActions = (payload, ServiceName) => {
  return async (dispatch) => {
    const { id, key, route } = payload;
    const loadingObj = {
      key,
      value: true
    };
    if (id) {
      dispatch(setLoading(loadingObj));
      const res = await ServiceName.patchItem(route, id);
      dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const fetchRecurring = (query) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.recurring;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await RecurringService.find(query);
      res && dispatch(pagePaginationSuccess({
        data: res,
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdateRecurring = payload => {
  return async dispatch => {
    const { id, ...rest } = payload;
    const key = PAGINATION_TYPES.recurring;
    let res;
    if (!id) {
      res = await RecurringService.create(rest);
      res && dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await RecurringService.patch(id, rest);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};

export const deleteRecurring = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.recurring;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await RecurringService.remove({ recurring_id: id });
      if (res?.success)
        dispatch(deletePaginationItem({
          key,
          value: id,
        }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};
