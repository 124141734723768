import { setLoading } from 'appRedux/reducers/Loading';
import { SalaryService } from 'services';
import { PAGINATION_TYPES } from 'util/constants';
import { pagePaginationSuccess, addPaginationItem, updatePaginationItem, deletePaginationItem, selectPaginationItem } from 'appRedux/reducers/Pagination';
import { REST_API } from 'services/rest.api';

export const fetchSalaries = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.salaries;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await SalaryService.find(query);
      res && dispatch(pagePaginationSuccess({
        data: res,
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const fetchSalaryById = (id) => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.salaries;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await SalaryService.findById(id);
      // const res = await SalaryService.find({id, company_id: 3, month: 7});
      const salary = selectPaginationItem({ key, id })(getState());
      if (!salary)
        res && dispatch(addPaginationItem({
          value: { ...res.salary, employees: res.data },
          key
        }));
      else
        res && dispatch(updatePaginationItem({
          key,
          id,
          value: { ...res.salary, employees: res.data }
        }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdateSalary = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.salaries;
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await SalaryService.create(rest);
      res && dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await SalaryService.patch(id, rest);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};

export const deleteSalary = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.salaries;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await SalaryService.remove({ salary_id: id });
      if (res?.success)
        dispatch(deletePaginationItem({
          key,
          value: id,
        }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};

export const handleSalaryActions = (payload) => {
  return async dispatch => {
    const { body, key, route, salaryId } = payload;
    const loadingObj = {
      key,
      value: true
    };
    if (typeof body === 'object') {
      dispatch(setLoading(loadingObj));
      const res = await SalaryService.patchItem(route, body);
      if (route === REST_API.SALARY_ROUTE.SALARY_HOLD || route === REST_API.SALARY_ROUTE.SALARY_HOLD_REMOVE) {
        res && dispatch(fetchSalaryById(salaryId));
      } else {
        res && dispatch(updatePaginationItem({
          key,
          id: body.salary_id,
          value: res
        }));
      }
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};
