import request from 'util/request';
import { FETCH_TYPES } from 'util/constants';
import { REST_API } from './rest.api';
import { ServiceBase } from './ServiceBase';

export class Groups extends ServiceBase {
  async createTree(group_id, body) {
    const options = { method: FETCH_TYPES.POST, body };
    return await request(this.API_ENDPOINT + '/' + group_id + REST_API.GROUPS.TREES, options);
  }
  async removeTree(body) {
    const options = { method: FETCH_TYPES.DELETE, body };
    return await request(this.API_ENDPOINT + REST_API.GROUPS.TREES, options);
  }
}
