import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = {
  message: { type: '', text: '' },
  navCollapsed: true,
  width: window.innerWidth,
  height: window.innerHeight,
  pathname: '/',
};

export const commonSlice = createSlice({
  name: 'common',
  initialState: INIT_STATE,
  reducers: {
    updateWindowSize: (state, { payload }) => ({ ...state, ...payload }),
    showMessage: (state, { payload }) => ({ ...state, message : payload }),
    hideMessage: (state) => ({ ...state, message : { type: '', text: '' } }),
    toggleCollapsedSideNav: (state, { payload }) => ({ ...state, navCollapsed : payload }),
    addCommonItems: (state, { payload }) => ({ ...state, [payload.key] : payload.value }),
  },
  extraReducers: {
    '@@router/LOCATION_CHANGE': (state, action) => ({
      ...state,
      pathname: action.payload.location.pathname,
      navCollapsed: false
    })
  }
});

export const { showMessage, hideMessage, updateWindowSize, toggleCollapsedSideNav, addCommonItems } = commonSlice.actions;

export default commonSlice.reducer;
