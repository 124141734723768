import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
//
import { onNavStyleChange } from 'appRedux/reducers/Settings';
import {
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from 'util/constants/ThemeSetting';


const SidebarLogo = () => {
  const dispatch = useDispatch();
  const { themeType, navStyle } = useSelector(({ uiSettings }) => uiSettings);
  const { width } = useSelector(({ common }) => common);

  return (
    <div className="gx-layout-sider-header">
      {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) ? <div className="gx-linebar">
        <i
          className={`gx-icon-btn icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
          onClick={() => {
            if (navStyle === NAV_STYLE_FIXED) {
              dispatch(onNavStyleChange(NAV_STYLE_MINI_SIDEBAR));
            } else {
              dispatch(onNavStyleChange(NAV_STYLE_FIXED));
            }
          }}
        />
      </div> : null}

      <Link to="/" className="gx-site-logo gx-ml-md-auto gx-ml-lg-auto">
        {(width >= TAB_SIZE) &&
            <img alt="logo2" height="35" width="50" src={require('assets/images/logos/icon-144.png')}/>}
      </Link>
    </div>
  );
};

export default SidebarLogo;
