import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
//
import Shifts from './Shifts';
import asyncComponent from 'util/asyncComponent';
import ErrorPage404 from 'routes/errorPages/404';

const Main = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/companies`} />
    <Route
      exact
      path={`${match.url}/companies`}
      component={asyncComponent(() => import('./Companies'))}
    />
    <Route
      exact
      path={`${match.url}/notices`}
      component={asyncComponent(() => import('./Notices'))}
    />
    <Route
      exact
      path={`${match.url}/offices/all`}
      component={asyncComponent(() => import('./Offices'))}
    />
    <Route
      exact
      path={`${match.url}/offices/devices`}
      component={asyncComponent(() => import('./Offices/Devices'))}
    />
    <Route path={`${match.url}/shifts`} component={Shifts} />
    <Route path='*' exact component={ErrorPage404} />
  </Switch>
);
Main.propTypes = {
  match: PropTypes.object
};
export default Main;
