import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = [];

export const ExamsSlice = createSlice({
  name: 'exams',
  initialState: INIT_STATE,
  reducers: {
    addExamsItem: (state, { payload }) => ([
      payload,
      ...state
    ]),
    updateExamsItem: (state, { payload }) => {
      const items = [...state];
      const currentIndex = items.findIndex(item => item.id === parseInt(payload.id));
      if (currentIndex === -1) return state;
      items[currentIndex] = {
        ...items[currentIndex],
        ...payload.value
      };
      return ([
        ...items
      ]);
    },
    deleteExamsItem: (state, { payload }) => {
      if (payload.isSubject) {
        return state.map(item =>({ ...item, subjects: item.subjects.filter(item => item.id !== parseInt(payload.value)) }));
      } else {
        return state.filter(item => item.id !== parseInt(payload.value));
      }
    },
    pageExamsSuccess: (state, { payload }) =>([
      ...payload
    ]),
  },
});

export const selectPaginationItem = payload => ({ exams }) => {
  const items = [...exams];
  const currentIndex = items.findIndex(item => item.id === parseInt(payload.id));
  if (currentIndex === -1) return null;
  return items[currentIndex];
};

export const { pageExamsSuccess, addExamsItem, updateExamsItem, deleteExamsItem } = ExamsSlice.actions;
export default ExamsSlice.reducer;
