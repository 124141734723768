import request from '../util/request';
import { REST_API } from './rest.api';
import { FETCH_TYPES } from '../util/constants';
import { CONFIG } from '../config/constants';

export class AuthenticationService {
  async login(body) {
    body.app_id = CONFIG.API_ID;
    const options = { method: FETCH_TYPES.POST, body };
    return await request(REST_API.loginUri, options);
  }
  async loginByEID(body) {
    body.app_id = CONFIG.API_ID;
    const options = { method: FETCH_TYPES.POST, body };
    return await request(REST_API.impersonate, options);
  }
  async emailRequest(body) {
    const options = { method: FETCH_TYPES.POST, body };
    return await request(REST_API.emailRequest, options);
  }
  async verifyToken(token) {
    const options = { method: FETCH_TYPES.GET };
    return await request(REST_API.verifyToken + token, options);
  }
  async passwordReset(body){
    const options = { method: FETCH_TYPES.POST, body };
    return await request(REST_API.passwordReset, options);
  }
  async logout() {
    return await new Promise((resolve, reject) => {
      const res = localStorage.removeItem('currentUser');
      if (res === undefined) resolve(({ status: 200, message: 'Signout Success' }));
      else reject(new Error('Signout Error'));
    });
  }
}
