import React, { useEffect } from 'react';
import { Button, Checkbox, Input, message, Form } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
//
import { userSignIn } from 'appRedux/actions/Auth';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress';
import { getAllowedRoutes } from 'util/helpers';
import PrivateRoutesConfig from 'config/PrivateRoutesConfig';

const SignIn = () => {

  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }) => auth);
  const loading = useSelector(({ loading }) => loading.login);

  const history = useHistory();

  useEffect(() => {
    const allowedRoutes = getAllowedRoutes(PrivateRoutesConfig) ?? [];
    if (authUser !== null) {
      history.push(allowedRoutes[0]?.path);
    }
  }, [authUser, history]);

  const onFinishFailed = errorInfo => {
    console.error('Failed:', errorInfo);
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-login-header gx-text-center">
          <h1 className="gx-login-title">Sign In</h1>
        </div>
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={values => dispatch(userSignIn(values))}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >

          <Form.Item
            rules={[{ required: true, message: 'The input is not valid E-mail!' }]}
            name="email"
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Please input your Password!' }]}
            name="password"
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item
              name="remember"
              valuePropName="checked"
              noStyle
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Link
              to="/forgotpassword"
              className="gx-float-right"
            >
              Forgot password
            </Link>
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              className="gx-mb-0 gx-w-100"
              htmlType="submit"
            >
              <IntlMessages id="app.userAuth.signIn" />
            </Button>
          </Form.Item>
        </Form>
      </div>

      {loader ? (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      ) : null}
      {showMessage ?
        message.error(alertMessage.toString()) : null}
    </div>
  );
};

export default SignIn;
