import { setLoading } from 'appRedux/reducers/Loading';
import { GroupsService } from 'services';
import { KEYS } from 'util/constants';
import { pageGroupsSuccess, addGroupsItem, updateGroupsItem, deleteGroupsItem } from 'appRedux/reducers/Groups';

export const fetchGroups = (searchParams) => {
  return async dispatch => {
    const key = KEYS.groups;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await GroupsService.find(searchParams);
      dispatch(pageGroupsSuccess(res));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Groups (fetchGroups)', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdateGroup = payload => {
  return async dispatch => {
    const { group_id, isGroup } = payload;
    let res;
    if (!group_id) {
      res = await GroupsService.create(payload);
      if (res.tree === null) res.tree = [];
      dispatch(addGroupsItem(res));
    } else {
      res = await (isGroup ? GroupsService.patch(group_id, payload) : GroupsService.createTree(group_id, payload));
      dispatch(updateGroupsItem({
        id: payload.group_id,
        value: res
      }));
    }
    return res;
  };
};

export const deleteGroup = item => {
  return async dispatch => {
    const key = KEYS.groups;
    const { id, isGroup } = item;
    const loadingObj = {
      key,
      value: true
    };
    // dispatch(setLoading(loadingObj));
    let res;
    if (item.id) {
      res = await (isGroup ? GroupsService.remove({ group_id: item.id }) : GroupsService.removeTree({ tree_id: item.id }));
      if (res?.success) {
        dispatch(deleteGroupsItem({
          isGroup,
          value: id,
        }));
      }
    }
    loadingObj.value = false;
    // dispatch(setLoading(loadingObj));
    return res;
  };
};
