import request from 'util/request';
import { encodeQuery } from 'util/helpers';
import { FETCH_TYPES } from 'util/constants';
import { ServiceBase } from './ServiceBase';
import { REST_API } from './rest.api';

const { ASSIGN_OFFICE, SETTING, ASSIGN_COMPANY, ASSETS_ASSIGN, ASSIGN_DEVICE, ASSIGN_GROUP, ASSIGN_SHIFT, ENABLE, DISABLE, ASSIGN_SALARY_BASIC, ASSIGN_SALARY_TAX } = REST_API.EMPLOYEES_ROUTE;
export class Employees extends ServiceBase {
  async search(query, body = {}) {
    const queryParam = encodeQuery(query);
    // eslint-disable-next-line no-empty
    if (body.hasOwnProperty('status')) {
    } else {
      body.status = true;
    }
    const options = { method: FETCH_TYPES.POST, body };
    return await request(this.API_ENDPOINT + '/search' + queryParam, options);
  }
  async createItem(id, route, body) {
    const options = { method: FETCH_TYPES.POST, body };
    if (route === REST_API.ADD_USER) {
      return await request(route, options);
    }
    return await request(this.API_ENDPOINT + '/' + id + route, options);
  }
  async patchItem(id, route, body) {
    const options = { method: FETCH_TYPES.PATCH, body };
    if (route === ASSIGN_COMPANY || route === ASSIGN_OFFICE || route === ASSIGN_GROUP || route === ASSETS_ASSIGN || route === ASSIGN_DEVICE || route === ENABLE || route === DISABLE || route === SETTING) {
      options.body.employee_id = parseInt(id);
      return await request(this.API_ENDPOINT + route, options);
    }
    if (route === ASSIGN_SHIFT || route === ASSIGN_SALARY_BASIC || route === ASSIGN_SALARY_TAX) {
      return await request(this.API_ENDPOINT + route, options);
    }
    return await request(this.API_ENDPOINT + '/' + id + route, options);
  }
  async removeItem(route, body) {
    const options = { method: FETCH_TYPES.DELETE, body };
    return await request(this.API_ENDPOINT + route, options);
  }
}
// path is employees[0].data.
// TODO use same function
// "general": {},
// "family": {
//   "members": []
// },
// "separation": {},
// "address": {},
// "history": [], done
// "educations": [],
// "achievements": [], done
// "languages": [], done
// "skills": [], done
// "trainings": [], done
// "assets": [],
// "contacts": [], done
// "references": [], done
// "files": []
