import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
//
import { AuthService } from '../services';

const FormItem = Form.Item;

const ResetPassword = () => {
  const history = useHistory();
  const { token } = useParams();
  const [isSubmitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState(undefined);
  const onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };
  const handleVerifyToken = useCallback(async () => {
    try {
      setSubmitting(true);
      const res = await AuthService.verifyToken(token);
      if (!res) {
        history.push('/');
      }
    } catch (error) {
      console.error('error-------->', error);
    }
    finally { setSubmitting(false); }
  }, [token, history]);
  useLayoutEffect(() => {
    handleVerifyToken();
  }, [handleVerifyToken]);
  const onFinish = async value => {
    const values = {
      plain_password: value.password,
      token
    };
    try {
      setSubmitting(true);
      const res = await AuthService.passwordReset(values);
      setMessage(res);
    } catch (error) {
      console.error('error-------->', error);
    }
    finally { setSubmitting(false); }
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">

        <div className="gx-login-header">
          {/* <img src={require("assets/images/logo-white.png")} alt="wieldy" title="wieldy"/> */}
        </div>
        <div className="gx-mb-4">
          <h2>Create new password.</h2>
        </div>
        {
          message && (
            <div>
              <p>{message?.split('Note:')[0]}</p>
            </div>
          )
        }
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          {!message && (
            <>
              <FormItem
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    max: 20,
                    min: 6,
                    message: 'Password must have a length between 6 and 20'
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Password"/>
              </FormItem>

              <FormItem
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password"/>
              </FormItem>
            </>

          )}
          <FormItem>
            {!message && (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isSubmitting}
                >
                Submit
                </Button>
              </>
            )}
            <Link to="/signin">
              <Button block>
                {!message ? 'Cancel' : 'SignIn'}
              </Button>
            </Link>
          </FormItem>
        </Form>

      </div>
    </div>
  );
};

export default ResetPassword;
