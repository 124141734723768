import request from 'util/request';
import { FETCH_TYPES } from 'util/constants';
import { encodeQuery } from 'util/helpers';

export class ServiceBase {
  constructor(url) {
    this.API_ENDPOINT = url;
  }
  async find(query){
    const queryParam = encodeQuery(query);
    const options = { method: FETCH_TYPES.GET };
    return await request(this.API_ENDPOINT + queryParam, options);
  }
  async findById(id) {
    const options = { method: FETCH_TYPES.GET };
    return await request(this.API_ENDPOINT + '/' + id, options);
  }
  async create(body) {
    const options = { method: FETCH_TYPES.POST, body };
    return await request(this.API_ENDPOINT, options);
  }
  async patch(id, body) {
    const options = { method: FETCH_TYPES.PATCH, body };
    return await request(this.API_ENDPOINT + '/' + id, options);
  }
  async remove(body) {
    const options = { method: FETCH_TYPES.DELETE, body };
    return await request(this.API_ENDPOINT, options);
  }
  // GET ALL STATIC API DATA
  /** route : string EX:  (EPR_/types)ROUTE API_API END POINT */
  async getItem(route, body) {
    const queryParam = encodeQuery(body);
    const options = { method: FETCH_TYPES.GET };
    return await request(
      this.API_ENDPOINT + route.split('_')[1] + queryParam,
      options,
    );
  }
}
