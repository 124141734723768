import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Popover } from 'antd';
import PropTypes from 'prop-types';
import { userSignOut } from 'appRedux/actions/Auth';

const UserInfo = ({ showName }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(({ auth }) => ({ user: auth.authUser?.user || {} }));
  const goToProfile = () => {
    if (!user?.employee_id) return;
    history.push(`/employees/all/${user?.employee_id}`);
  };
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => goToProfile()}>My Profile
      </li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          src={'https://via.placeholder.com/150'}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        { showName && (
          <span className="gx-avatar-name">{user?.username}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
        )}
      </Popover>
    </div>
  );

};

UserInfo.propTypes = {
  showName: PropTypes.bool
};

export default UserInfo;
