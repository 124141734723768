import { AuthenticationService } from './authentication.service';
import { Employees } from './employees.service';
import { Leaves } from './leaves.service';
import { Groups } from './groups.service';
import { Exams } from './exams.service';
import { Assets } from './assets.service';
import { Company } from './company.service';
import { Offices } from './offices.service';
import { Devices } from './devices.service';
import { Shifts } from './shifts.service';
import { Holidays } from './holidays.service';
import { PaySchedules } from './paySchedules.service';
import { PayScheduleType } from './payScheduleType.service';
import { Loans } from './loans.service';
import { Salary } from './salary.service';
import { Bonus } from './bonus.service';
import { EPR } from './eprs.service';
import { KPI } from './kpis.service';
import { RemoteWork } from './remoteWork.service';
import { Report } from './report.service';
import { Activity } from './activity.service';

import { REST_API } from './rest.api';
import { ServiceBase } from './ServiceBase';

const AuthService = new AuthenticationService();
const AssetsService = new Assets(REST_API.assets);
const EmployeeService = new Employees(REST_API.employees);
const LeavesService = new Leaves(REST_API.leave);
const GroupsService = new Groups(REST_API.groups);
const ExamsService = new Exams(REST_API.exams);
const CompanyService = new Company(REST_API.companies);
const OfficesService = new Offices(REST_API.offices);
const DevicesService = new Devices(REST_API.devices);
const NoticesService = new Devices(REST_API.notices);
const ShiftsService = new Shifts(REST_API.shifts);
const HolidaysService = new Holidays(REST_API.holidays);
const EprsService = new EPR(REST_API.eprs);
const KpiService = new KPI(REST_API.kpis);
const RemoteWorkService = new RemoteWork(REST_API.remote_work);
const ReportService = new Report(REST_API.report);
const PayScheduleService = new PaySchedules(REST_API.pay_schedules);
const PayScheduleTypeService = new PayScheduleType(REST_API.pay_schedule_type);
const LoansService = new Loans(REST_API.loans);
const SalaryService = new Salary(REST_API.salaries);
const BonusService = new Bonus(REST_API.bonuses);
const RecurringService = new ServiceBase(REST_API.pay_recurrings);
const DesignationService = new ServiceBase(REST_API.designations);
const ActivityService = new Activity(REST_API.activity);

export {
  AuthService,
  AssetsService,
  EmployeeService,
  LeavesService,
  GroupsService,
  ExamsService,
  OfficesService,
  DevicesService,
  NoticesService,
  ShiftsService,
  HolidaysService,
  CompanyService,
  EprsService,
  KpiService,
  RemoteWorkService,
  ReportService,
  PayScheduleService,
  PayScheduleTypeService,
  LoansService,
  SalaryService,
  BonusService,
  RecurringService,
  DesignationService,
  ActivityService,
};
