import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
//
import Main from './main';
import Employees from './employees';
import ErrorPages from './errorPages';
import ErrorPage404 from './errorPages/404';

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}main`}
        component={Main}
      />
      <Route
        path={`${match.url}employees`}
        component={Employees}
      />
      <Route
        path={`${match.url}error-pages`}
        component={ErrorPages}
      />
      <Route path='*' exact component={ErrorPage404} />
    </Switch>
  </div>
);

App.propTypes = {
  match: PropTypes.object
};

export default App;
