import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
//
import Sidebar from '../Sidebar';
import Topbar from '../Topbar';
import { footerText } from 'util/config';
import App from 'routes';
import {
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR
} from 'util/constants/ThemeSetting';
// import Customizer from '../Customizer';

const { Content, Footer } = Layout;

const MainApp = () => {

  const { navStyle } = useSelector(({ uiSettings }) => uiSettings);
  const match = useRouteMatch();

  const getNavStyles = (navStyle) => {
    switch (navStyle) {
    case NAV_STYLE_FIXED :
      return <Topbar/>;
    case NAV_STYLE_MINI_SIDEBAR :
      return <Topbar/>;
    default :
      return null;
    }
  };


  return (
    <Layout className="gx-app-layout">
      <Sidebar/>
      <Layout>
        {getNavStyles(navStyle)}
        <Content className={'gx-layout-content'}>
          <App match={match}/>
          <Footer>
            <div className="gx-layout-footer-content">
              {footerText}
            </div>
          </Footer>
        </Content>
        {/* <Customizer/> */}
      </Layout>
    </Layout>
  );
};
export default MainApp;

