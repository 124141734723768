import { setLoading } from 'appRedux/reducers/Loading';
import { ActivityService } from 'services';
import { PAGINATION_TYPES } from 'util/constants';
import {
  pagePaginationSuccess,
} from 'appRedux/reducers/Pagination';

export const getActivity = ({ page = 1, limit, ...searchParams }) => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.activity;
    const loadingObj = {
      key,
      value: true,
    };
    try {
      dispatch(setLoading(loadingObj));
      const {
        items,
        pagination: { self, last },
      } = getState().pagination[key];
      const res =
        (!(parseInt(self) === last) || Object.values(searchParams).length > 0) &&
        (await ActivityService.find(
          {
            limit,
            page,
            sort: 'id,desc',
            ...searchParams,
          }
        ));

      res.items = page > 1 ? [...items, ...res.items] : res.items;
      dispatch(
        pagePaginationSuccess({
          data: res,
          key,
        })
      );
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};


export const fetchActivity = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.activity;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await ActivityService.find(query);
      res && dispatch(pagePaginationSuccess({
        data: res,
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};
