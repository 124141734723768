import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
//
import asyncComponent from 'util/asyncComponent';
import ErrorPage404 from 'routes/errorPages/404';

const Shift = ({ match }) => (
  <Switch>
    <Redirect
      exact
      from={`${match.url}/`}
      to={`${match.url}/all`} />
    <Route
      exact
      path={`${match.url}/all`}
      component={asyncComponent(() => import('./AllShifts'))}
    />
    <Route
      exact
      path={`${match.url}/assigns`}
      component={asyncComponent(() => import('./AssignList'))}
    />
    <Route
      path='*'
      component={ErrorPage404}
    />
  </Switch>
);

Shift.propTypes = {
  match: PropTypes.object
};

export default Shift;
