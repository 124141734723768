import React, { useEffect, useState } from 'react';
import { Input, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
//
import { BonusService } from 'services';
import { numberWithCommas } from 'util/helpers';
const RightAlignMentColumn = ['Basic (GS 50%)', 'Net Pay'];
const BonusPreview = () => {
  const { id: itemId } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const res = await BonusService.getItem('BONUS_/csv', {
          bonus_id: itemId,
        });
        res && setData(res?.split('\r')[0]?.split('\n') ?? []);
      } catch (err) {
        console.error('PRINT IN %s=====>', 'BonusPreview START ***', err);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [itemId]);

  const trTd = React.useMemo(
    () =>
      data?.map((row) => {
        let rowData = row?.split(',').map((td) => td?.replace(/"/g, ''));
        // Remove the last element if it's an empty string
        if (rowData.length > 1 && rowData[rowData.length - 1] === '') {
          rowData.pop();
        }
        return rowData;
      }),
    [data]
  );

  const tableDta = React.useMemo(() => {
    let items = [];
    for (let i = 6; i < trTd.length - 1; i++) {
      var result = trTd[i]?.reduce(function (result, field, index) {
        result[trTd[5][index]] = field;
        return result;
      }, {});
      items.push({ ...result });
    }
    return items;
  }, [trTd]);

  const headerData = React.useMemo(() => {
    let items = [];
    for (let i = 1; i < 5; i++) {
      var result = trTd[i]?.reduce(function (result, field, index) {
        result[index] = field;
        return result;
      }, {});
      items.push({ ...result });
    }
    return items;
  }, [trTd]);
  const handleSearch = (value, dataIndex, confirm, clearFilters) => {
    setTimeout(() => {
      confirm();
    }, 300);
    if (value[0]?.trim()) {
      setSearchedColumn(dataIndex);
      return;
    }
    setSearchedColumn('');
    clearFilters();
  };
  let searchInput = React.useRef(null);
  // eslint-disable-next-line no-lone-blocks
  { /* eslint-disable */}  
const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          allowClear
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, dataIndex, confirm, clearFilters)
          }
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <span style={{ backgroundColor: "#ffc069", padding: 0 }}>{text}</span>
      ) : (
        text
      ),
  });
{/* eslint-enable */}
  return (
    <Spin spinning={isLoading}>
      <div
        style={{
          height: window.innerHeight,
          backgroundColor: '#FFF',
          overflow: 'scroll',
        }}
      >
        {headerData?.map((item, i) => (
          <div
            key={i}
            style={{ padding: 8 }}
          >
            <span style={{ fontWeight: 'bold' }}>
              {item[0] ?? ''}&nbsp;&nbsp;
            </span>
            <span style={{ fontWeight: 'bold' }}>
              {item[1] ?? ''}&nbsp;&nbsp;
            </span>
          </div>
        ))}
        <table style={{ borderCollapse: 'collapse' }}>
          <tr style={{ backgroundColor: '#E2E2E2' }}>
            {trTd[5]?.map((v) => (
              <th
                key={v}
                style={{
                  paddingTop: 12,
                  paddingBottom: 12,
                  minWidth: 180,
                  textAlign: `${RightAlignMentColumn.includes(v) ? 'Right' : 'left'}`,
                  fontWeight: '500',
                  border: '1px solid #ddd',
                  padding: 8,
                }}
              >
                {v}
              </th>
            ))}
          </tr>
          {tableDta?.map((v, index) => (
            <tr
              key={v['ID NO']}
              style={{
                backgroundColor: `${index % 2 === 0 ? '#FFFFFF' : '#f2f2f2'}`,
              }}
            >
              {Object.keys(v)?.map((item) => (
                <td
                  key={item}
                  style={{
                    border: '1px solid #ddd',
                    textAlign: `${RightAlignMentColumn.includes(item) ? 'Right' : 'left'}`,
                    padding: 8,
                  }}
                >
                  {numberWithCommas(v[item])}
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
    </Spin>
  );
};

export default BonusPreview;
