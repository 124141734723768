/*
* These are the placeholder roles you can replace these with yours
*/
export default {
  ROLE_BO_ADMIN: 'ROLE_BO_ADMIN',
  ROLE_BO_IMPERSONATE: 'ROLE_BO_IMPERSONATE',
  ROLE_BO_USER: 'ROLE_BO_USER',
  ROLE_BO_EMPLOYEE: 'ROLE_BO_EMPLOYEE',
  ROLE_BO_PAYROLL: 'ROLE_BO_PAYROLL',
  ROLE_BO_LOAN: 'ROLE_BO_LOAN',
  ROLE_BO_SALARY: 'ROLE_BO_SALARY',
  ROLE_BO_SALARY_ADMIN: 'ROLE_BO_SALARY_ADMIN',
  ROLE_BO_SALARY_EDIT: 'ROLE_BO_SALARY_EDIT',
  ROLE_BO_PAY_SCHEDULE: 'ROLE_BO_PAY_SCHEDULE',
  ROLE_BO_EPR: 'ROLE_BO_EPR',
  ROLE_BO_KPI: 'ROLE_BO_KPI',
  ROLE_BO_REMOTE_WORK: 'ROLE_BO_REMOTE_WORK',
  ROLE_BO_LEAVE: 'ROLE_BO_LEAVE',
  ROLE_BACK_OFFICE: 'ROLE_BACK_OFFICE',
  ROLE_USER: 'ROLE_USER',
  ROLE_BO_TRANSFER_APPROVE: 'ROLE_BO_TRANSFER_APPROVE',
  ROLE_BO_PROMOTION_APPROVE: 'ROLE_BO_PROMOTION_APPROVE',
  ROLE_BO_DISCIPLINARY_APPROVE: 'ROLE_BO_DISCIPLINARY_APPROVE',
  ROLE_BO_NOTICE: 'ROLE_BO_NOTICE',
};
