import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
//
import IntlMessages from 'util/IntlMessages';
import { AuthService } from '../services';

const FormItem = Form.Item;


const ForgotPassword = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState(undefined);
  const onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  const onFinish = async values => {
    try {
      setSubmitting(true);
      const res = await AuthService.emailRequest(values);
      setMessage(res);
    } catch (error) {
      console.error('error-------->', error);
    }
    finally { setSubmitting(false);}
  };

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">

        <div className="gx-login-header">
          {/* <img src={require("assets/images/logo-white.png")} alt="wieldy" title="wieldy"/> */}
        </div>
        <div className="gx-mb-4">
          <h2>Forgot Your Password ?</h2>
          <p><IntlMessages id="app.userAuth.forgot"/></p>
        </div>
        {
          message && (
            <div>
              <p>{message?.split('Note:')[0]}</p>
              <p><strong>Note: </strong>{message?.split('Note:')[1]}</p>
            </div>
          )
        }
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="gx-signin-form gx-form-row0"
        >
          {!message && (
            <FormItem
              name="email"
              rules={[
                { type: 'email', message: 'The input is not valid E-mail!' },
                { required: true, message: 'Please input your E-mail!' }
              ]}
            >
              <Input
                className='gx-input-lineheight'
                type="email"
                placeholder="E-Mail Address"
              />
            </FormItem>
          )}
          <FormItem>
            {!message && (
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={isSubmitting}
              >
                <IntlMessages id="app.userAuth.send"/>
              </Button>
            )}
            <Link to="/signin">
              <Button block>
                  Back
              </Button>
            </Link>
          </FormItem>
        </Form>

      </div>
    </div>
  );
};

export default ForgotPassword;
