import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//
import { toggleCollapsedSideNav } from 'appRedux/reducers/Common';
import Auxiliary from 'util/Auxiliary';
import UserInfo from 'components/UserInfo';
import { NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from 'util/constants/ThemeSetting';

const { Header } = Layout;

const Topbar = () => {

  const { navStyle } = useSelector(({ uiSettings }) => uiSettings);
  const { navCollapsed, width } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  if (width < TAB_SIZE)
    return (
      <Header>
        { ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR)) ? (
          <div className="gx-linebar gx-mr-3">
            <i
              className="gx-icon-btn icon icon-menu"
              onClick={() => {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              }}
            />
          </div>
        ) : null}
        <Link
          to="/"
          className="gx-d-block gx-d-lg-none gx-pointer"
        >
          <img
            alt=""
            height="30"
            width="40"
            src={require('assets/images/logos/icon-144.png')}
          />
        </Link>
        <ul className="gx-header-notifications gx-ml-auto">
          <li className="gx-language">

          </li>
        </ul>
        {width >= TAB_SIZE ? null : (
          <Auxiliary>
            <li className="gx-user-nav"><UserInfo/></li>
          </Auxiliary>
        )}
      </Header>
    );
  else
    return null;
};

export default Topbar;
