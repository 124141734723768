import { ServiceBase } from './ServiceBase';
import request from 'util/request';
import { FETCH_TYPES } from 'util/constants';
import { REST_API } from './rest.api';

export class Report extends ServiceBase {
  async createAttendance(body) {
    const options = { method: FETCH_TYPES.POST, body };
    return await request(REST_API.attendance_manual, options);
  }
}
