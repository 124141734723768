import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';
//
import { CONFIG } from 'config/constants';
import 'assets/vendors/style';
import store from 'appRedux/store';
import { history } from 'appRedux/reducers';
import App from 'containers/App/index';
const { GOOGLE_MAPS_API_KEY } = CONFIG;

const NextApp = () => (
  <Provider store={store}>
    <LoadScript
      id="script-loader"
      googleMapsApiKey={GOOGLE_MAPS_API_KEY}
      language="en"
      region="us"
    >
      <ConnectedRouter history={history}>
        <Switch>
          <Route
            path="/"
            component={App}
          />
        </Switch>
      </ConnectedRouter>
    </LoadScript>
  </Provider>
);


export default NextApp;
