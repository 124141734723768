import React, { useEffect, useState } from 'react';
import { Input, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
//
import { SalaryService } from 'services';
import { numberWithCommas } from 'util/helpers';

function CSVToArray(strData, strDelimiter) {
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  strDelimiter = strDelimiter || ',';

  // Create a regular expression to parse the CSV values.
  var objPattern = new RegExp(
    // Delimiters.
    '(\\' +
      strDelimiter +
      '|\\r?\\n|\\r|^)' +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      '([^"\\' +
      strDelimiter +
      '\\r\\n]*))',
    'gi'
  );

  // Create an array to hold our data. Give the array
  // a default empty first row.
  var arrData = [[]];

  // Create an array to hold our individual pattern
  // matching groups.
  var arrMatches = null;

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while ((arrMatches = objPattern.exec(strData))) {
    // Get the delimiter that was found.
    var strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);
    }

    var strMatchedValue;

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[3];
    }

    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  // Return the parsed data.
  return arrData;
}
const RightAlignMentColumn = ['Basic', 'House rent', 'Conveyance', 'Medical', 'Others', 'Gross pay', 'Allowance', 'PF', 'I Tax', 'Deduction', 'Net pay', 'PF company', 'Service length'];
const SalaryPreview = () => {
  const { id: salaryId } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const res = await SalaryService.getItem('SALARY_/csv', {
          salary_id: salaryId,
        });
        res && setData(res?.split('\r')[0]?.split('\n') ?? []);
      } catch (err) {
        console.error('PRINT IN %s=====>', 'SalaryPreview START ***', err);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [salaryId]);

  const trTd = React.useMemo(
    () =>
      data?.map((row, index) => {
        let result = [];
        if (index > 6 && index < data.length - 2) {
          result = CSVToArray(row)[0];
        } else {
          result = row?.split(',').map((td) => td?.replace(/"/g, ''));
        }
        return result;
      }),
    [data]
  );

  const tableDta = React.useMemo(() => {
    let items = [];
    for (let i = 7; i < trTd.length - 1; i++) {
      var result = trTd[i]?.reduce(function (result, field, index) {
        result[index] = field;
        return result;
      }, {});
      items.push({ ...result });
    }
    return items;
  }, [trTd]);
  const headerData = React.useMemo(() => {
    let items = [];
    for (let i = 1; i < 6; i++) {
      var result = trTd[i]?.reduce(function (result, field, index) {
        result[trTd[6][index]] = field;
        return result;
      }, {});
      items.push({ ...result, id: i });
    }
    return items;
  }, [trTd]);
  const handleSearch = (value, dataIndex, confirm, clearFilters) => {
    setTimeout(() => {
      confirm();
    }, 300);
    if (value[0]?.trim()) {
      setSearchedColumn(dataIndex);
      return;
    }
    setSearchedColumn('');
    clearFilters();
  };
  let searchInput = React.useRef(null);
  // eslint-disable-next-line no-lone-blocks
  { /* eslint-disable */}  
const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          allowClear
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, dataIndex, confirm, clearFilters)
          }
        />
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",

    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <span style={{ backgroundColor: "#ffc069", padding: 0 }}>{text}</span>
      ) : (
        text
      ),
  });
{/* eslint-enable */}
  return (
    <Spin spinning={isLoading}>
      <div
        style={{
          height: window.innerHeight,
          backgroundColor: '#FFF',
          overflow: 'scroll',
        }}
      >
        {headerData?.map((item, i) => (
          <div
            key={i}
            style={{ padding: 8 }}
          >
            <span style={{ fontWeight: 'bold' }}>
              {item['Id No'] ?? ''}&nbsp;&nbsp;
            </span>
            <span>{item?.Name}</span>
          </div>
        ))}
        <table style={{ borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ backgroundColor: '#E2E2E2' }}>
              {trTd[6]?.map((v, index) => (
                <th
                  key={v + index.toString()}
                  style={{
                    paddingTop: 12,
                    paddingBottom: 12,
                    minWidth: 180,
                    textAlign: `${RightAlignMentColumn.includes(v) ? 'Right' : 'left'}`,
                    fontWeight: '500',
                    border: '1px solid #ddd',
                    padding: 8,
                  }}
                >
                  {v}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableDta?.map((v, index) => (
              <tr
                key={v[0]}
                style={{
                  backgroundColor: `${index % 2 === 0 ? '#FFFFFF' : '#f2f2f2'}`,
                }}
              >
                {Object.keys(v)?.map((item) => (
                  <td
                    key={item}
                    style={{
                      border: '1px solid #ddd',
                      textAlign: `${RightAlignMentColumn.includes(trTd[6][item]) ? 'Right' : 'left'}`,
                      padding: 8,
                      color: v[item] === 'On hold' ? 'red' : null
                    }}
                  >
                    {numberWithCommas(v[item])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Spin>
  );
};

export default SalaryPreview;
