import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import URLSearchParams from 'url-search-params';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import AppLocale from 'lngProvider';
import MainApp from './MainApp';
import SignIn from '../SignIn';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import { setInitUrl } from 'appRedux/reducers/Auth';
import { setThemeType, onNavStyleChange, onLayoutTypeChange } from 'appRedux/reducers/Settings';
import { getAllowedRoutes } from 'util/helpers';
import PrivateRoutesConfig from 'config/PrivateRoutesConfig';
import SalaryPreview from '../SalaryPreview';
import BonusPreview from '../BonusPreview';
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  THEME_TYPE_DARK
} from 'util/constants/ThemeSetting';

// eslint-disable-next-line react/prop-types
const RestrictedRoute = ({ component: Component, location, tokenCheck, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      tokenCheck
        ? <Component {...props} />
        : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location }
            }}
          />
        )}
  />
);


const App = () => {

  const dispatch = useDispatch();
  const { locale, themeType, layoutType } = useSelector(({ uiSettings }) => uiSettings);
  const { authUser, initURL } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const tokenCheck = () => {
    const itemStr = localStorage.getItem('currentUser');
    if (!itemStr) {
      return false;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem('currentUser');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has('theme')) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has('nav-style')) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has('layout-type')) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
  });


  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  useEffect(() => {
    const allowedRoutes = getAllowedRoutes(PrivateRoutesConfig) ?? [];
    if (location.pathname === '/') {
      if (authUser === null) {
        history.push('/signin');
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        history.push(allowedRoutes[0]?.path);
      } else {
        history.push(initURL);
      }
    }
  }, [authUser, initURL, location, history]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
      document.body.classList.add('dark-theme');
      let link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = '/css/dark_theme.css';
      link.className = 'style_dark_theme';
      document.body.appendChild(link);
    }}, [themeType]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >

        <Switch>
          <Route
            exact
            path='/signin'
            component={SignIn}
          />
          <Route
            exact
            path='/forgotpassword'
            component={ForgotPassword}
          />
          <Route
            path='/resetpassword/:token'
            component={ResetPassword}
          />
          <Route
            path='/preview/:id'
            component={SalaryPreview}
          />
          <Route
            path='/bonus-preview/:id'
            component={BonusPreview}
          />
          <RestrictedRoute
            path={`${match.url}`}
            tokenCheck={tokenCheck()}
            location={location}
            component={MainApp}
          />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
