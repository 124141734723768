import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createRootReducer from '../reducers';

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
  })
];

const rootReducer = (state, action) => {
  if (action.type === 'auth/userSignOutSuccess') { // check for action type
    state = {};
  }
  return createRootReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production'
});

export default store;
