import { setLoading } from 'appRedux/reducers/Loading';
import { ExamsService } from 'services';
import { KEYS } from 'util/constants';
import { pageExamsSuccess, addExamsItem, updateExamsItem, deleteExamsItem } from 'appRedux/reducers/Exams';

export const fetchExams = (searchParams) => {
  return async (dispatch) => {
    const key = KEYS.exams;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await ExamsService.find(searchParams);
      dispatch(pageExamsSuccess(res.map(item => ({ ...item, subjects: item.subjects.reverse() })).reverse()));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdateExam = payload => {
  return async dispatch => {
    const { exam_id, isSubject } = payload;
    let res;
    if (!exam_id) {
      res = await ExamsService.create(payload);
      res && dispatch(addExamsItem(res));
    } else {
      res = await (isSubject ? ExamsService.createSubject(exam_id, payload) : ExamsService.patch(exam_id, payload));
      res && dispatch(updateExamsItem({
        id: payload.exam_id,
        value: { ...res, subjects: res.subjects.reverse() }
      }));
    }
    return res;
  };
};

export const deleteExam = item => {
  return async dispatch => {
    const key = KEYS.exams;
    const { id, isSubject } = item;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await (isSubject ? ExamsService.removeSubject({ subject_id: id }) : ExamsService.remove({ exam_id: id }));
      if (res?.success) {
        dispatch(deleteExamsItem({
          isSubject,
          value: id,
        }));
      }
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};
