import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import asyncComponent from 'util/asyncComponent';
import ErrorPage404 from 'routes/errorPages/404';

const Employees = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />
    <Route
      exact
      path={`${match.url}/all`}
      component={asyncComponent(() => import('./Employees'))}
    />
    <Route
      path={`${match.url}/all/:id`}
      component={asyncComponent(() => import('./Employees/Employee'))}
    />
    <Route
      path={`${match.url}/groups`}
      component={asyncComponent(() => import('./Groups'))}
    />
    <Route
      path={`${match.url}/activity`}
      component={asyncComponent(() => import('./Activity'))}
    />
    <Route
      path={`${match.url}/assets`}
      component={asyncComponent(() => import('./Assets'))}
    />
    <Route
      path={`${match.url}/promotion-transfer`}
      component={asyncComponent(() => import('./PromotionTransfer'))}
    />
    <Route
      path={`${match.url}/attendance`}
      component={asyncComponent(() => import('./Attendance'))}
    />
    <Route
      exact
      path={`${match.url}/exams`}
      component={asyncComponent(() => import('./Exams'))}
    />
    <Route
      path={`${match.url}/exams/:id`}
      component={asyncComponent(() => import('./Exams/Subjects'))}
    />
    <Route
      path={`${match.url}/application`}
      component={asyncComponent(() => import('./Application'))}
    />
    <Route
      path={`${match.url}/performance`}
      component={asyncComponent(() => import('./Performance'))}
    />
    <Route
      path={`${match.url}/payroll`}
      component={asyncComponent(() => import('./PayRoll'))}
    />
    <Route path='*' exact component={ErrorPage404} />
  </Switch>
);

Employees.propTypes = {
  match: PropTypes.object
};

export default Employees;
