import { ServiceBase } from './ServiceBase';
import request from 'util/request';
import { FETCH_TYPES } from 'util/constants';

export class Salary extends ServiceBase {
  async patchItem(route, body) {
    const options = { method: FETCH_TYPES.PATCH, body };
    return await request(this.API_ENDPOINT + route, options);
  }
}
