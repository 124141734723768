import { createSlice } from '@reduxjs/toolkit';
import { PAGINATION_TYPES } from 'util/constants';

export const paginateObj = {
  count: 0,
  total: 0,
  items: [],
  pagination: {
    self: 0,
    first: 0,
    last: 1
  },
};

const initialState = {};

Object.keys(PAGINATION_TYPES).map(key => initialState[PAGINATION_TYPES[key]] = { ...paginateObj });


export const PaginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    addPaginationItem: (state, { payload }) => ({
      ...state,
      [payload.key]: {
        ...state[payload.key],
        items: [payload.value, ...state[payload.key].items],
        total: state[payload.key].total + 1,
      }
    }),
    updatePaginationItem: (state, { payload }) => {
      const items = [...state[payload.key].items];
      const currentIndex = items.findIndex(item => item.id === parseInt(payload.id));
      if (currentIndex === -1) return state;
      items[currentIndex] = {
        ...items[currentIndex],
        ...payload.value
      };
      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          items,
        }
      };
    },
    deletePaginationItem: (state, { payload }) => {
      return {
        ...state,
        [payload.key]: {
          ...state[payload.key],
          items: state[payload.key].items.filter(item => item.id !== parseInt(payload.value)),
          total: state[payload.key].total - 1,
        }
      };
    },
    pagePaginationSuccess: (state, { payload }) =>({
      ...state,
      [payload.key]: payload.data
    }),
  },
});

export const selectPaginationItem = payload => ({ pagination }) => {
  const items = [...pagination[payload.key].items];
  const currentIndex = items.findIndex(item => item.id === parseInt(payload.id));
  if (currentIndex === -1) return null;
  return items[currentIndex];
};

export const { pagePaginationSuccess, addPaginationItem, updatePaginationItem, deletePaginationItem } = PaginationSlice.actions;
export default PaginationSlice.reducer;
