import { createSlice } from '@reduxjs/toolkit';

const INIT_STATE = [];

export const GroupsSlice = createSlice({
  name: 'groups',
  initialState: INIT_STATE,
  reducers: {
    addGroupsItem: (state, { payload }) => ([
      ...state,
      payload
    ]),
    updateGroupsItem: (state, { payload }) => {
      const items = [...state];
      const currentIndex = items.findIndex(item => item.id === parseInt(payload.id));
      if (currentIndex === -1) return state;
      items[currentIndex] = {
        ...items[currentIndex],
        ...payload.value
      };
      return ([
        ...items
      ]);
    },
    deleteGroupsItem: (state, { payload }) => {
      const { isGroup, value } = payload;
      if (isGroup) {
        return state.filter(item => item.id !== parseInt(value));
      } else {
        return state.map(item =>({ ...item, tree: item.tree.filter(item => item.id !== parseInt(value)) }));
      }
    },
    pageGroupsSuccess: (state, { payload }) =>([
      ...payload
    ]),
  },
});

export const selectGropusItem = payload => ({ pagination }) => {
  const items = [...pagination[payload.key].items];
  const currentIndex = items.findIndex(item => item.id === parseInt(payload.id));
  if (currentIndex === -1) return null;
  return items[currentIndex];
};

export const { pageGroupsSuccess, addGroupsItem, updateGroupsItem, deleteGroupsItem } = GroupsSlice.actions;
export default GroupsSlice.reducer;
