import { setLoading } from 'appRedux/reducers/Loading';
import { BonusService } from 'services';
import { PAGINATION_TYPES } from 'util/constants';
import { pagePaginationSuccess, addPaginationItem, updatePaginationItem, deletePaginationItem, selectPaginationItem } from 'appRedux/reducers/Pagination';
import { REST_API } from 'services/rest.api';

export const fetchBonuses = (query) => {
  return async dispatch => {
    const key = PAGINATION_TYPES.bonuses;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await BonusService.find(query);
      res && dispatch(pagePaginationSuccess({
        data: res,
        key
      }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const fetchBonusById = (id) => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.bonuses;
    const loadingObj = {
      key,
      value: true
    };
    try {
      dispatch(setLoading(loadingObj));
      const res = await BonusService.findById(id);
      // const res = await BonusService.find({id, company_id: 3, month: 7});
      const bonus = selectPaginationItem({ key, id })(getState());
      if (!bonus)
        res && dispatch(addPaginationItem({
          value: res,
          key
        }));
      else
        res && dispatch(updatePaginationItem({
          key,
          id,
          value: res
        }));
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e);
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};

export const addOrUpdateBonus = payload => {
  return async dispatch => {
    const key = PAGINATION_TYPES.bonuses;
    const { id, ...rest } = payload;
    let res;
    if (!id) {
      res = await BonusService.create(rest);
      res && dispatch(addPaginationItem({
        value: res,
        key
      }));
    } else {
      res = await BonusService.patch(id, rest);
      res && dispatch(updatePaginationItem({
        key,
        id,
        value: res
      }));
    }
    return res;
  };
};

export const deleteBonus = id => {
  return async dispatch => {
    const key = PAGINATION_TYPES.bonuses;
    const loadingObj = {
      key,
      value: true
    };
    dispatch(setLoading(loadingObj));
    let res;
    if (id) {
      res = await BonusService.remove({ bonus_id: id });
      if (res?.success)
        dispatch(deletePaginationItem({
          key,
          value: id,
        }));
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
    return res;
  };
};

export const handleBonusActions = (payload) => {
  return async dispatch => {
    const { body, key, route, bonusId } = payload;
    const loadingObj = {
      key,
      value: true
    };
    if (typeof body === 'object') {
      dispatch(setLoading(loadingObj));
      const res = await BonusService.patchItem(route, body);
      if (route === REST_API.BONUS_ROUTE.BONUS_HOLD || route === REST_API.BONUS_ROUTE.BONUS_HOLD_REMOVE) {
        res && dispatch(fetchBonusById(bonusId));
      } else {
        res && dispatch(updatePaginationItem({
          key,
          id: body.bonus_id,
          value: res
        }));
      }
    }
    loadingObj.value = false;
    dispatch(setLoading(loadingObj));
  };
};
