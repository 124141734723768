import { setLoading } from 'appRedux/reducers/Loading';
import { userSignInSuccess, userSignOutSuccess, setInitUrl } from 'appRedux/reducers/Auth';
import { AuthService } from 'services';
import { getAllowedRoutes } from 'util/helpers';
import PrivateRoutesConfig from 'config/PrivateRoutesConfig';

export const userSignIn = payload => {
  return async dispatch => {
    const { email, password } = payload;
    const loadingObj = { key: 'login', value: true };
    try {
      dispatch(setLoading(loadingObj));
      const signInUser = await AuthService.login(({ username: email, password: password }));
      if (signInUser?.user?.token) {
        const now = new Date();
        const item = {
          ...signInUser.user,
          expiry: now.getTime() + 22 * 60 * 60 * 10000,
        };
        localStorage.setItem('currentUser', JSON.stringify(item));
        dispatch(userSignInSuccess(signInUser));
        const allowedRoutes = getAllowedRoutes(PrivateRoutesConfig) ?? [];
        dispatch(setInitUrl(allowedRoutes[0]?.path));
      }
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Auth', e);
    } finally {
      loadingObj.value = false;
      dispatch(setLoading(loadingObj));
    }
  };
};

export const userSignOut = () => {
  return async dispatch => {
    try {
      const signOutUser = await AuthService.logout();
      if (signOutUser.status === 200) {
        dispatch(userSignOutSuccess());
      } else {
        // dispatch(setMessage(signOutUser.message));
      }
    } catch (error) {
      // dispatch(setMessage(error));
    }
  };
};

